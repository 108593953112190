import img1 from "./img/2m.png";
import img2 from "./img/3m.png";
import img3 from "./img/4m.png";
export const sliderData = [
  {
    img: img1,
    alt: "arte huichol",
  },
  {
    img: img2,
    alt: "arte indigena 2",
  },
  {
    img: img3,
    alt: "flor indigena",
  },
];
