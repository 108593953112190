import React from "react";

function ColorBar(props) {
  return (
    <div className="h-[40px]  bg-gradient-to-r  from-[#f6c930] via-[#e98604] to-[#b62f02] ">
    </div>
  );
}


export default ColorBar;
